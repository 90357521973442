<template>
  <div>
    <b-modal
      id="new-deal-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-deal-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-deal-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Deal</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit="createNewDeal" @reset="resetForm">
        <div class="input-row row">
          <div class="col-md-8 d-flex align-items-center">
            <div class="image-upload w-100">
              <label for="deal-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="dealImage"
                id="deal-image-input"
                class="mt-3"
                ref="dealImageRef"
              />
            </div>
          </div>

          <div class="col-md-4 deal-image-preview">
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else>
              <h6>No image added</h6>
            </template>
          </div>
        </div>

        <div class="input-row row">
          <div class="col-md-12">
            <b-form-input
              required
              type="text"
              v-model="form.name"
              placeholder="Enter Deal Name"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-12">
            <search-store-bar
              storePassed=""
              @sendStore="getStoreDetails"
              ref="validateStore"
            />
          </div>
          <div class="col-12">
            <b-form-select
              :disabled="processingRequest === 'requesting'"
              v-model="form.storeId"
              :options="stores"
              @change="storeChanged"
            />
          </div>
        </div>
        <div class="input-row row">
          <div class="col-12">
            <multi-select
              required
              v-model="form.products"
              :options="productsOptions"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option._id"
              no-options-text="No products"
              selected-no-options-text="No product selected"
              @change="changedProductsList"
            />
          </div>
        </div>
        <div class="input-row row">
          <div class="col-12" style="font-size: 12px; text-align: right">
            Price of the selected Products: <b>{{ this.form.totalPrice }}</b>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-md-6">
            <b-form-datepicker
              id="end-date-datepicker"
              v-model="form.expiryDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              class="mb-2"
              :required="true"
            ></b-form-datepicker>
          </div>
          <div class="col-md-6">
            <b-form-input
              required
              type="number"
              v-model="form.price"
              placeholder="Deal Price"
            ></b-form-input>
          </div>
        </div>

        <div class="input-row row">
          <div class="col-12">
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="input-row center">
          <button
            type="Submit"
            class="create-button"
            :class="processingRequest"
          >
            Submit
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
            />
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import SearchStoreBar from "@/view/layout/dashboard_components/SearchStoreBar";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
export default {
  components: {
    "search-store-bar": SearchStoreBar,
    "multi-select": vMultiselectListbox
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        price: "",
        storeName: "",
        storeId: null,
        products: [],
        totalPrice: 0,
        expiryDate: ""
      },
      productsOptions: [],
      stores: [],
      imageUrl: null,
      hasError: false,
      processingError: "",
      processingRequest: "",
      dealImage: null
    };
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("new-deal-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("new-deal-modal");
    },
    uploadFile: function() {
      this.dealImage = this.$refs.dealImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.dealImage);
    },
    getStoreDetails: function(stores) {
      this.productsOptions = [];
      this.form.products = [];
      this.form.totalPrice = 0;
      if (stores !== null && stores.length > 0) {
        const newStores = [
          {
            value: null,
            text: "Select a store."
          }
        ];
        stores.forEach(store =>
          newStores.push({
            value: store.storeId,
            text: store.storeName
          })
        );
        this.stores = newStores;
      } else {
        this.stores = [
          {
            value: null,
            text: "No stores found."
          }
        ];
      }
    },
    storeChanged: function(val) {
      const storeName = this.stores.find(x => x.value === val);
      this.form.storeName = storeName;
      if (val !== null) {
        this.fetchingStoreProducts = true;
        this.processingRequest = "processing";
        ApiService.get(`/store/${val}/products`).then(({ data: response }) => {
          this.productsOptions = response.products;
          this.processingRequest = "";
        });
      } else {
        this.productsOptions = [];
        this.form.products = [];
        this.form.totalPrice = 0;
      }
    },
    changedProductsList: function(vals) {
      const sum = vals.reduce((s, p) => s + p.price, 0);
      this.form.totalPrice = parseFloat(sum.toFixed(2));
    },
    resetForm: function() {
      const defaultForm = {
        baskytorLevel: "",
        name: "",
        description: "",
        levelPremium: "",
        ratingTarget: "",
        orderTarget: ""
      };
      this.imageUrl = null;
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.dealImage = null;
    },
    createNewDeal: function(e) {
      e.preventDefault();
      const tk = JwtService.getToken();
      this.form.token = `${tk}`;
      this.hasError = "d-none";
      this.processingError = "";
      this.processingRequest = "processing";
      ApiService.post("/deals/addDeal", this.form).then(res => {
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
          return;
        }
        if (res.data.success) {
          if (this.dealImage !== null) {
            const newFormData = new FormData();
            newFormData.append("image", this.dealImage);
            newFormData.append("dealId", res.data.data._id);
            newFormData.append("token", `${tk}`);
            ApiService.post("/deals/uploadImage", newFormData).then(res => {
              if (res.data.success === 0) {
                this.hasError = "d-flex";
                this.processingError = res.data.message;
                this.processingRequest = "";
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
              } else {
                this.processingRequest = "";
                this.$emit("updateDeals");
              }
            });
          } else {
            this.processingRequest = "";
            this.$emit("updateDeals");
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .msl-multi-select {
  width: 100%;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-deal-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      .deal-image-preview {
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }
      .image-upload {
        label {
          display: flex;
          align-items: center;
          span.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 32px;
              color: #000;
            }
            padding: 25px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
    }
  }
}
</style>
