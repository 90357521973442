<template>
  <div>
    <b-modal
      id="edit-product-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="edit-product-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('edit-product-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Edit Product</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="sendEditProduct" @reset="resetForm">
        <div class="row py-2">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Product Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="images"
                id="rider-iamge-input"
                class="mt-3"
                ref="imagesRef"
              />
            </div>
          </div>
          <div class="col-md-6 product-image-preview">
            <div class="image-block">
              <template v-if="imageUrl !== null">
                <img :src="imageUrl" alt="Vendor image" />
              </template>
              <template
                v-else-if="
                  productToEdit != null && productToEdit.images.length > 0
                "
              >
                <StackedImages :images="productToEdit.images" type="product" />
              </template>
              <template
                v-else-if="
                  productToEdit !== null && productToEdit.images === undefined
                "
              >
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-md-6">
            <b-form-checkbox
              id="checkbox-1"
              v-model="replace"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              Replace the old image(s)
            </b-form-checkbox>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-md-6">
            <label for="name">Name</label>
            <b-form-input
              required
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="code">SKU code</label>
            <b-form-input
              required
              v-model="form.sku"
              placeholder="Code"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-md-2">
            <label for="unit-price">Unit Price</label>
            <b-form-input
              required
              type="number"
              :min="0"
              step="any"
              v-model="form.price"
              placeholder="Price"
            ></b-form-input>
          </div>
          <div class="col-md-2">
            <label for="unit">Discount</label>
            <div class="d-flex align-items-end">
              <b-form-checkbox
                id="discount-checkbox"
                v-model="form.isDiscounted"
                name="age-limited"
                :value="true"
                :unchecked-value="false"
              >
                Discount
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-4">
            <label for="discount-percentage">Discount Percentage</label>
            <b-form-input
              required
              type="number"
              :min="0"
              :max="100"
              step="any"
              v-model="form.discountPercentage"
              placeholder="Discont Percentage"
              @change="discountPercentChange"
              :disabled="!form.isDiscounted"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="discount-price">Discount Price</label>
            <b-form-input
              required
              type="number"
              :min="0"
              :max="form.price"
              step="any"
              v-model="form.discountPrice"
              placeholder="Discount Price"
              @change="discountPriceChange"
              :disabled="!form.isDiscounted"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-6">
            <label for="business-line">Business Line</label>
            <b-form-select
              v-model="form.businessLine"
              :options="businessLines"
              @change="businessLineChanged"
              disabled
            ></b-form-select>
          </div>
          <template v-if="region === 'US'">
            <div class="col-6 place-center">
              <div class="d-flex align-items-end">
                <b-form-checkbox
                  id="age-limited"
                  v-model="form.ageLimited"
                  name="age-limited"
                  :value="true"
                  :unchecked-value="false"
                >
                  Age Limited(21+)
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>
        <template v-if="region === 'US'">
          <div class="row py-2">
            <div class="col-6">
              <div class="d-flex align-items-end">
                <b-form-checkbox
                  id="schedule-only-checkbox"
                  v-model="form.scheduleOnly"
                  name="schdeule-only"
                  :value="true"
                  :unchecked-value="false"
                >
                  Schedule only
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </template>
        <div class="row py-2">
          <div class="col-md-4">
            <label for="unit">Unit</label>

            <b-form-select
              required
              v-model="form.unit"
              :options="unitOptions"
            ></b-form-select>
          </div>
          <template v-if="!catsDisabled">
            <div class="col-md-4">
              <label for="store">Categories</label>
              <b-form-select
                v-model="form.categoryId"
                :options="categoryOptions"
                :disabled="catsDisabled"
                @change="catChanged"
              ></b-form-select>
            </div>
          </template>
          <template v-if="!storesDisabled">
            <div class="col-md-4">
              <label for="store">Store</label>
              <search-store-bar
                :storePassed="form.storeName"
                @sendStore="getStoreDetails"
                ref="validateStore"
              />
            </div>
          </template>
          <template
            v-if="
              (!storesDisabled && !subCategoryDisabled) ||
                (!catsDisabled && !subCategoryDisabled)
            "
          >
            <div class="col-md-4">
              <label for="sub-category">Sub Category</label>
              <b-form-select
                v-model="form.subCategoryId"
                :options="subCategoryOptions"
                :disabled="
                  (storesDisabled && !subCategoryDisabled) ||
                    (!catsDisabled && !subCategoryDisabled)
                "
              ></b-form-select>
            </div>
          </template>
        </div>
        <div class="row py-2">
          <div class="col-md-3">
            <label for="unit">Squad</label>
            <div class="d-flex align-items-end">
              <b-form-checkbox
                id="squad-type"
                v-model="form.squadType"
                name="squad-type"
                :value="true"
                :unchecked-value="false"
              >
                Squad Type
              </b-form-checkbox>
            </div>
          </div>
          <template>
            <div class="col-md-3">
              <label for="squad-discount">Discount</label>
              <b-input-group size="md">
                <b-input-group-prepend>
                  <span class="group-prepend shadow-sm">
                    2
                    <i class="fa fa-user"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.discountOnTwo"
                  placeholder="Enter value"
                  :disabled="!form.squadType"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-3">
              <label for="squad-discount">Discount</label>
              <b-input-group size="md">
                <b-input-group-prepend>
                  <span class="group-prepend shadow-sm">
                    3
                    <i class="fa fa-user"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.discountOnThree"
                  placeholder="Enter value"
                  :disabled="!form.squadType"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-3">
              <label for="squad-discount">Discount</label>
              <b-input-group size="md">
                <b-input-group-prepend>
                  <span class="group-prepend shadow-sm">
                    4
                    <i class="fa fa-user"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.discountOnFour"
                  placeholder="Enter value"
                  :disabled="!form.squadType"
                ></b-form-input>
              </b-input-group>
            </div>
          </template>
        </div>
        <template v-if="form.squadType">
          <div class="row py-2">
            <div class="col-md-6">
              <div class="image-upload">
                <label for="squad-image-input" class="image-upload-label">
                  <span class="icon">
                    <i class="fa fa-camera"></i>
                  </span>
                  <span class="text">Squad Image</span>
                </label>
                <input
                  @change="uploadSquadImage"
                  type="file"
                  name="images"
                  id="squad-image-input"
                  class="mt-3"
                  ref="squadImagesRef"
                />
              </div>
            </div>
            <div class="col-md-6 product-image-preview">
              <div class="image-block">
                <template v-if="squadImage !== null">
                  <img :src="squadImageUrl" alt="Vendor image" />
                </template>
                <template
                  v-else-if="
                    productToEdit != null &&
                      productToEdit.squadEnabled.squadImages.length > 0
                  "
                >
                  <StackedImages
                    :images="productToEdit.squadEnabled.squadImages"
                    type="product"
                  />
                </template>
                <template
                  v-else-if="
                    productToEdit !== null &&
                      productToEdit.squadEnabled.squadImages === undefined
                  "
                >
                  <h6>No image added</h6>
                </template>
              </div>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-6">
              <b-form-checkbox
                id="squad-image-checkbox-1"
                v-model="replaceSquadImage"
                name="squad-image-checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                Replace the old image(s)
              </b-form-checkbox>
            </div>
          </div>
        </template>
        <div class="row py-1">
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="row py-1 flex-column">
          <label for="raw-materials">Raw Materials</label>
          <vue-tags-input
            v-model="rawmaterial"
            :tags="rawMaterials"
            placeholder="Add Raw materials"
            @tags-changed="newTags => (rawMaterials = newTags.text)"
          />
        </div>
        <div class="row py-1 flex-column">
          <label for="keywords">Keywords</label>
          <vue-tags-input
            v-model="keyword"
            :tags="keywordsTag"
            placeholder="Add Keywords"
            @tags-changed="tagsChangedKey"
          />
        </div>
        <div class="row d-flex justify-content-center p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import SearchStoreBar from "@/view/layout/dashboard_components/SearchStoreBar";
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";
import VueTagsInput from "@johmun/vue-tags-input";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    VueTagsInput,
    StackedImages,
    "search-store-bar": SearchStoreBar
  },
  props: {
    productToEdit: Object
  },
  data() {
    return {
      form: {
        name: "",
        price: "",
        sku: "",
        unit: "",
        description: "",
        categoryId: null,
        subCategoryId: null,
        keywords: [],
        storeId: null,
        discountPercentage: "",
        discountPrice: "",
        isDiscounted: false,
        productType: "",
        businessLine: null,
        discountOnFour: 0,
        discountOnThree: 0,
        discountOnTwo: 0,
        squadImages: [],
        storeName: "",
        scheduleOnly: false
      },
      categoryOptions: [
        {
          value: null,
          text: "All Categories",
          cats: []
        }
      ],
      stores: [],

      imageUrl: null,
      images: null,
      replace: false,
      replaceSquadImage: false,
      squadDiscount: "",
      keywordsTag: [],
      rawMaterials: [],
      squadNumber: 2,
      squadType: false,
      keyword: "",
      rawmaterial: "",
      typeOptions: [
        { text: "Food", value: "Food" },
        { text: "Grocery", value: "Grocery" }
      ],
      squadOptions: [
        {
          text: "2",
          value: 2
        },
        {
          text: "3",
          value: 3
        },
        {
          text: "4",
          value: 4
        }
      ],
      unitOptions: [
        { text: "Unit", value: "unit" },
        { text: "Dozen", value: "doz" },
        { text: "Piece", value: "pc" },
        { text: "Box", value: "box" },
        { text: "MilliGram", value: "mg" },
        { text: "Gram", value: "g" },
        { text: "Kilogram", value: "kg" },
        { text: "Ounce", value: "oz" },
        { text: "Pound", value: "lbs" },
        { text: "Millilitre", value: "ml" },
        { text: "Litre", value: "ltr" },
        { text: "Gallon", value: "gal" }
      ],
      subCategoryOptions: null,
      processingRequest: "",
      squadImageUrl: null,
      squadImage: null,
      hasError: false,
      processingError: "",
      callingFetchStores: false,
      callingFetchSubCats: false,
      catsDisabled: true,
      subCategoryDisabled: true,
      storesDisabled: true
    };
  },
  mounted() {
    const val = this.productToEdit;
    this.process(val);
  },
  methods: {
    ...mapGetters(["getAllCategories", "getAllStores", "getAllBusinessLines"]),
    showModal: function() {
      this.$bvModal.show("edit-product-modal");
    },
    process: async function(val) {
      await this.$store.dispatch(GET_ALL_BUSINESS_LINES);
      this.form.name = val.name;
      this.form.name = val.name;
      this.form.price = val.price;
      this.form.sku = val.sku;
      this.form.unit = val.unit;
      this.form.description = val.description;

      this.images = null;
      this.form.keywords = val.keywords;
      this.form.discountPercentage = val.discountPercentage;
      this.form.discountPrice = val.discountPrice;
      this.form.isDiscounted = val.isDiscounted;
      this.form.productType = val.productType;
      this.form.ageLimited = val.ageLimited ? true : false;
      this.form.scheduleOnly = val.scheduleOnly ? true : false;
      this.squadImage = null;
      if (val.squadEnabled.state) {
        this.form.squadType = val.squadEnabled.state;
        this.form.discountOnFour = val.squadEnabled.discountOnFour;
        this.form.discountOnThree = val.squadEnabled.discountOnThree;
        this.form.discountOnTwo = val.squadEnabled.discountOnTwo;
        this.form.squadImages = val.squadEnabled.squadImages;
      }
      this.form.productId = val._id;
      this.form.businessLine = this.businessLines.find(
        line => line.text.toLowerCase() === this.form.productType.toLowerCase()
      ).value;
      await this.businessLineChanged(this.form.businessLine);
      this.form.subCategoryId = val.subCategory;
      if (this.form.productType.toLowerCase() !== "food") {
        this.form.categoryId = this.categoryOptions.find(c =>
          c.subCategories.some(
            cs => cs._id.toString() === this.form.subCategoryId
          )
        ).value;
        await this.catChanged(this.form.categoryId);
      } else {
        this.form.storeId = val.store._id;
        this.form.storeName = val.store.storeName;

        await this.storeChanged(this.form.storeId);
        this.storesDisabled = false;
      }
      this.subCategoryDisabled = false;
    },
    extractTable: function(arr) {
      let array = [];
      array.push({
        value: null,
        text: "All Stores",
        subs: []
      });
      arr.map(item => {
        let s = {};
        s.value = item._id;
        if (item.name) {
          s.text = item.name;
        } else {
          s.text = item.storeName;
        }
        s.subs = item.subCategories;
        array.push(s);
      });
      this.stores = array;
    },
    uploadFile: function() {
      this.images = this.$refs.imagesRef.files[0];
      this.imageUrl = URL.createObjectURL(this.images);
    },
    uploadSquadImage: function() {
      this.squadImage = this.$refs.squadImagesRef.files[0];
      this.squadImageUrl = URL.createObjectURL(this.squadImage);
    },

    businessLineChanged: async function(val) {
      this.subCategoryDisabled = true;
      this.catsDisabled = true;
      this.form.subCategoryId = null;
      this.subCategoryOptions = null;
      this.categoryOptions = null;

      if (val === null) return;
      /*  Extract the businessline from the businesslines array*/
      let newItem = this.businessLines.find(
        item => item.value === this.form.businessLine
      );
      /* EXtract the anme to lowercase */
      let name = newItem.text.toLowerCase();
      /* Set the product type  */
      this.form.productType = newItem.text;
      /* If there is a busiessline selected and it is grocery */
      if (this.form.businessLine !== null && name === "grocery") {
        /* Get all the categories from the database for that businessline */
        const tk = JwtService.getToken();
        await ApiService.post("/businessLine/getBusinessLinesCategories", {
          businessLineId: val,
          token: `${tk}`
        }).then(({ data: resCategories }) => {
          const categoriesFetched = resCategories.data;

          /* Create an empty array */
          let arr = [];
          /* The categories are loaded in via vuex */
          /* Chec of the categories are more than 1 */
          if (categoriesFetched.length > 0) {
            /* There were categories found for the business line */
            /* Push a default category option to the array */

            /* Loop through all the categories fetched from db */
            /* Place then in the categories array */
            this.categoryOptions = categoriesFetched.map(item => ({
              text: item.name,
              value: item._id,
              subCategories: item.subCategory
            }));
            /* Set the category options to the created array */
            this.categoryOptions.push({
              value: null,
              text: "All Categories",
              subCategories: []
            });
          } else {
            /* No category was fetched */
            /* Set the default option to no category was found */
            arr.push({
              value: null,
              text: "No Categories Found",
              subCategories: []
            });
            /* Set the ctegory options */
            this.categoryOptions = arr;
          }
          /* Set the categories disabled to false */
          this.catsDisabled = false;
        });
      } else if (this.form.businessLine !== null) {
        /* If no businessline was selected disable the categories */
        this.catsDisabled = true;
        this.callingFetchStores = true;
        const tk = JwtService.getToken();
        await ApiService.post("/store/showStoresbyBusinessLines", {
          token: `${tk}`,
          storeType: `${val}`
        }).then(res => {
          this.extractTable(res.data.data);
          this.callingFetchStores = false;
        });
        this.form.categoryId = null;
        this.form.storeId = null;
      }
    },
    catChanged: async function(value) {
      this.subCategoryOptions = null;

      if (value === null) return;
      this.callingFetchSubCats = true;
      const { data: response } = await ApiService.get(
        `/subCategories/category/${value}`
      ).catch(err => console.log(err));
      if (!response.error) {
        if (response.subCategories.length < 1) {
          this.subCategoryOptions = [
            { text: "No Sub Category was found.", value: null }
          ];
          this.subCategoryDisabled = false;
        } else {
          this.subCategoryOptions = response.subCategories.map(item => ({
            text: item.name,
            value: item._id
          }));
          this.subCategoryOptions.push({ text: "SubCategories", value: null });
          this.subCategoryDisabled = false;
        }
      }
    },
    getStoreDetails: async function(obj) {
      if (this.form.storeId !== obj.storeId) {
        this.form.storeId = obj.storeId;
        this.storeChanged(this.form.storeId);
      }
      this.form.subCategoryId = null;
    },
    storeChanged: async function(val) {
      this.subCategoryDisabled = true;

      const { data: response } = await ApiService.get(
        `/subCategories/store/${val}`
      ).catch(err => console.log(err));
      if (!response.error) {
        if (response.subCategories.length < 1) {
          this.subCategoryOptions = [
            { text: "No Sub Category was found.", value: null }
          ];
          this.subCategoryDisabled = false;
        } else {
          this.subCategoryOptions = response.subCategories.map(item => ({
            text: item.name,
            value: item._id
          }));
          this.subCategoryOptions.push({ text: "SubCategories", value: null });
          this.subCategoryDisabled = false;
        }
      }
    },
    discountPercentChange: function(val) {
      const { price } = this.form;
      if (price !== "" && price > 0) {
        let out = (price * val) / 100;
        this.form.discountPrice = out.toFixed(2);
      }
    },
    discountPriceChange: function(val) {
      const { price } = this.form;
      if (val > price) {
        this.form.discountPrice = price.toFixed(2);
        val = this.form.discountPrice;
      }
      if (price !== "" && price > 0) {
        const out = (val / price) * 100;
        this.form.discountPercentage = out.toFixed(2);
      }
    },

    resetForm: function() {
      this.form.name = "";
      this.form.price = "";
      this.form.sku = "";
      this.form.unit = "";
      this.form.description = "";
      this.images = null;
      this.form.categoryId = null;
      this.form.subCategoryId = null;
      this.form.keywords = [];
      this.form.storeId = null;
      this.form.discountPercentage = "";
      this.form.discountPrice = "";
      this.form.isDiscounted = false;
      this.form.productType = "";
      this.form.productId = "";
      this.$emit("resetEditProduct");
    },
    sendEditProduct: async function(e) {
      e.preventDefault();
      if (
        !this.storesDisabled &&
        !this.form.storeId &&
        !this.$refs.validateStore.parentSubmittable()
      ) {
        return;
      }
      const tk = JwtService.getToken();
      this.form.token = tk;
      this.processingRequest = "processing";
      ApiService.post("/products/product/edit", this.form).then(res => {
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
          return;
        }
        if (res.data.success) {
          if (this.images !== null) {
            ApiService.setHeader("content-type", "multipart/form-data");
            const formData = new FormData();
            formData.append("image", this.images);
            formData.append("productId", this.form.productId);
            formData.append("replace", this.replace);
            formData.append("token", `${tk}`);
            ApiService.post("/products/uploadImage", formData).then(
              (/* res1 */) => {
                if (this.squadImage !== null) {
                  const newFormData = new FormData();
                  newFormData.append("image", this.squadImage);
                  newFormData.append("productId", this.form.productId);
                  newFormData.append("replace", true);
                  newFormData.append("token", `${tk}`);
                  ApiService.post("/products/uploadSquadImage", newFormData)
                    .then((/* cRes */) => {
                      this.processingRequest = "";
                      this.resetForm();
                      this.$emit("updateProducts");
                      this.$bvModal.hide("edit-product-modal");
                      ApiService.setHeader("content-type", "application/json");
                    })
                    .catch((/* error */) => {
                      setTimeout(() => {
                        this.$emit("updateProducts");
                      }, 100);
                      ApiService.setHeader("content-type", "application/json");
                    });
                }
                this.processingRequest = "";
                this.resetForm();
                this.$emit("updateProducts");
                this.$bvModal.hide("edit-product-modal");
                ApiService.setHeader("content-type", "application/json");
              }
            );
          } else if (this.squadImage !== null) {
            const newFormData = new FormData();
            newFormData.append("image", this.squadImage);
            newFormData.append("productId", this.form.productId);
            newFormData.append("replace", true);
            newFormData.append("token", `${tk}`);
            ApiService.post("/products/uploadSquadImage", newFormData)
              .then((/* cRes */) => {
                this.processingRequest = "";
                this.resetForm();
                this.$emit("updateProducts");
                this.$bvModal.hide("edit-product-modal");
                ApiService.setHeader("content-type", "application/json");
              })
              .catch((/* error */) => {
                setTimeout(() => {
                  this.$emit("updateProducts");
                }, 100);
                ApiService.setHeader("content-type", "application/json");
              });
          } else {
            this.processingRequest = "";
            this.resetForm();
            this.$emit("updateProducts");
            this.$bvModal.hide("edit-product-modal");
            ApiService.setHeader("content-type", "application/json");
          }
        }
      });
    },
    tagsChangedKey: function(newTags) {
      let arr = [];
      newTags.map(item => {
        arr.push(item.text);
      });
      return (this.keywordsTag = newTags);
    }
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region,
      categories: state => state.categories.allCategories,
      businessLines: state => {
        const arr = [];
        arr.push({ text: "Business Lines", value: null, cats: [], stores: [] });
        state.businessLines.allBusinessLines.map(item => {
          arr.push({
            text: item.name,
            value: item._id,
            cats: item.categories,
            stores: item.vendors
          });
        });
        return arr;
      }
    })
  }
};
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .edit-product-modal {
  background: #ffffff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .product-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .image-upload {
    label {
      display: flex;
      align-items: center;
      span.icon {
        &.attachment {
          display: flex;
          .text {
            max-width: 100px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
  .group-prepend {
    display: flex;
    align-items: center;
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
    background: #fff;
    padding-right: 5px;
    i {
      margin: 0 5px;
    }
  }

  .vue-tags-input {
    max-width: 100%;
    width: 100%;
    .ti-tag {
      background: #fff;
      color: #000;
    }
  }
}
</style>
