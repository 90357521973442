<template>
  <div class="products">
    <div class="row">
      <div class="top-row w-100">
        <div
          @click="createNewProduct"
          class="create-new-btn shadow-sm"
          v-if="!activeTab"
        >
          New Product
        </div>
        <div
          @click="createNewDeal"
          class="create-new-btn shadow-sm"
          v-if="activeTab === 3"
        >
          New Deal
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Products
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Archive
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 2 ? 'active' : ''"
            @click="changeTab(2)"
          >
            Upload Products
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 3 ? 'active' : ''"
            @click="changeTab(3)"
          >
            Deals
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <template v-if="activeTab === 0">
          <ActiveProducts
            ref="activeProductsRef"
            @editProduct="editProduct"
            :productToEdit="productToEdit"
          />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 1">
          <ArchivedProducts />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 2">
          <UploadProducts />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 3">
          <deals ref="dealsComponentRef" @createNewDeal="createNewDeal" />
        </template>
      </b-tab>
    </b-tabs>
    <template v-if="processNewProduct">
      <NewProduct ref="newProductModal" @updateProducts="updateProducts" />
    </template>
    <template v-if="processNewDeal">
      <new-deal ref="newDealModal" @updateDeals="updateDeals" />
    </template>
    <template v-if="productToEdit !== null">
      <EditProduct
        ref="editProductModal"
        @updateProducts="updateProducts"
        :productToEdit="productToEdit"
        @resetEditProduct="resetEditProduct"
      />
    </template>
  </div>
</template>

<script>
import ActiveProducts from "@/view/pages/products/ActiveProducts";
import UploadProducts from "@/view/pages/products/UploadProducts";
import ArchivedProducts from "@/view/pages/products/ArchivedProducts";
import NewProduct from "@/view/pages/products/NewProduct";
import EditProduct from "@/view/pages/products/EditProduct";
import Deals from "@/view/pages/deals/Deals.vue";
import NewDeal from "@/view/pages/deals/NewDeal.vue";

export default {
  components: {
    ActiveProducts,
    ArchivedProducts,
    NewProduct,
    EditProduct,
    UploadProducts,
    deals: Deals,
    "new-deal": NewDeal
  },
  data() {
    return {
      activeTab: 0,
      productToEdit: null,
      processNewProduct: false,
      processNewDeal: false
    };
  },

  methods: {
    changeTab: function(index) {
      this.activeTab = index;
    },
    createNewProduct: function() {
      this.processNewProduct = true;
      setTimeout(() => {
        this.$refs.newProductModal.showModal();
      }, 100);
    },
    createNewDeal: function() {
      this.processNewDeal = true;
      setTimeout(() => {
        this.$refs.newDealModal.showModal();
      }, 100);
    },
    editProduct: function(product) {
      this.productToEdit = product;
    },
    resetEditProduct: function() {
      this.productToEdit = null;
    },
    updateProducts: function() {
      if (this.processNewProduct) this.processNewProduct = false;
      this.$refs.activeProductsRef.updateProducts();
    },
    updateDeals: function() {
      this.$refs.dealsComponentRef.updateDeals();
      this.processNewDeal = false;
      setTimeout(() => {
        this.$refs.newDealModal.closeModal();
      }, 100);
    }
  },
  watch: {
    productToEdit: function(x) {
      if (x !== null)
        setTimeout(() => {
          this.$refs.editProductModal.showModal();
        }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.products {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;

    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
