<template>
  <div>
    <b-modal
      id="new-product-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="new-product-modal"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-product-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Product</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="createNewProduct">
        <div class="row py-2">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Product Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="images"
                id="rider-iamge-input"
                class="mt-3"
                ref="imagesRef"
                required
              />
            </div>
          </div>
          <div class="col-md-6 product-image-preview">
            <div class="image-block">
              <template v-if="imageUrl !== null">
                <img :src="imageUrl" alt="Level image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-md-6">
            <label for="name">Name</label>
            <b-form-input
              required
              v-model="form.productName"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="code">SKU code</label>
            <b-form-input
              required
              v-model="form.sku"
              placeholder="Code"
            ></b-form-input>
          </div>
          <div class="col-md-2">
            <label for="unit-price">Unit Price</label>
            <b-form-input
              required
              type="number"
              :min="0"
              step="any"
              v-model="form.price"
              placeholder="Price"
            ></b-form-input>
          </div>
          <div class="col-md-2">
            <label for="discount">Discount</label>
            <div class="d-flex align-items-end">
              <b-form-checkbox
                id="discount-checkbox"
                v-model="form.isDiscounted"
                name="squad-checkbox"
                :value="true"
                :unchecked-value="false"
              >
                Discount
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-4">
            <label for="discount-percentage">Discount Percentage</label>
            <b-form-input
              required
              type="number"
              :min="0"
              :max="100"
              step="any"
              v-model="form.discountPercentage"
              placeholder="Discont Percentage"
              @change="discountPercentChange"
              :disabled="!form.isDiscounted"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="discount-price">Discount Price</label>
            <b-form-input
              required
              type="number"
              :min="0"
              :max="form.price"
              step="any"
              v-model="form.discountPrice"
              placeholder="Discount Price"
              @change="discountPriceChange"
              :disabled="!form.isDiscounted"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-6">
            <label for="business-line">Business Line</label>
            <b-form-select
              v-model="form.businessLine"
              :options="businessLines"
              @change="businessLineChanged"
            ></b-form-select>
          </div>
          <template v-if="region === 'US'">
            <div class="col-6 place-center">
              <div class="d-flex align-items-end">
                <b-form-checkbox
                  id="age-limited-checkbox"
                  v-model="form.ageLimited"
                  name="age-limited"
                  :value="true"
                  :unchecked-value="false"
                >
                  Age Limited(21+)
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>
        <template v-if="region === 'US'">
          <div class="row py-2">
            <div class="col-6">
              <div class="d-flex align-items-end">
                <b-form-checkbox
                  id="schedule-only-checkbox"
                  v-model="form.scheduleOnly"
                  name="schdeule-only"
                  :value="true"
                  :unchecked-value="false"
                >
                  Schedule only
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </template>
        <div class="row py-2">
          <div class="col-md-4">
            <label for="unit">Unit</label>
            <b-form-select
              required
              v-model="form.unit"
              :options="unitOptions"
            ></b-form-select>
          </div>
          <template v-if="!catsDisabled">
            <div class="col-md-4">
              <label for="store">Categories</label>
              <b-form-select
                v-model="form.categoryId"
                :options="categoryOptions"
                :disabled="catsDisabled"
                @change="catChanged"
              ></b-form-select>
            </div>
          </template>
          <template v-if="!storesDisabled">
            <div class="col-md-4">
              <label for="store">Store</label>
              <search-store-bar
                :storePassed="form.storeName"
                @sendStore="getStoreDetails"
              />
            </div>
          </template>
          <template
            v-if="!storesDisabled && (stores !== null || store.length > 0)"
          >
            <div class="col-md-4">
              <label for="store">Select Store</label>
              <b-form-select
                v-model="form.storeId"
                :options="stores"
                @change="storeChanged"
              ></b-form-select>
            </div>
          </template>
          <template
            v-if="
              (!storesDisabled && !subCategoryDisabled) ||
                (!catsDisabled && !subCategoryDisabled)
            "
          >
            <div class="col-md-4">
              <label for="sub-category">Sub Category</label>
              <b-form-select
                v-model="form.subCategoryId"
                :options="subCategoryOptions"
                :disabled="subCategoryDisabled"
              ></b-form-select>
            </div>
          </template>
        </div>
        <div class="row py-2">
          <div class="col-md-3">
            <label for="squd-checkbox">Squad</label>
            <div class="d-flex align-items-end">
              <b-form-checkbox
                id="squad-checkbox"
                v-model="form.squadType"
                name="squad-checkbox"
                :value="true"
                :unchecked-value="false"
              >
                Squad Type
              </b-form-checkbox>
            </div>
          </div>
          <template v-if="form.squadType">
            <div class="col-md-3">
              <label for="squad-discount">Discount</label>
              <b-input-group size="md">
                <b-input-group-prepend>
                  <span class="group-prepend shadow-sm">
                    2
                    <i class="fa fa-user"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.discountOnTwo"
                  placeholder="Enter value"
                  :disabled="!form.squadType"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-3">
              <label for="squad-discount">Discount</label>
              <b-input-group size="md">
                <b-input-group-prepend>
                  <span class="group-prepend shadow-sm">
                    3
                    <i class="fa fa-user"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.discountOnThree"
                  placeholder="Enter value"
                  :disabled="!form.squadType"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-3">
              <label for="squad-discount">Discount</label>
              <b-input-group size="md">
                <b-input-group-prepend>
                  <span class="group-prepend shadow-sm">
                    4
                    <i class="fa fa-user"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.discountOnFour"
                  placeholder="Enter value"
                  :disabled="!form.squadType"
                ></b-form-input>
              </b-input-group>
            </div>
          </template>
        </div>
        <template v-if="form.squadType">
          <div class="row py-2">
            <div class="col-md-6">
              <div class="image-upload">
                <label for="squad-image-input" class="image-upload-label">
                  <span class="icon">
                    <i class="fa fa-camera"></i>
                  </span>
                  <span class="text">Squad Image</span>
                </label>
                <input
                  @change="uploadSquadImage"
                  type="file"
                  name="images"
                  id="squad-image-input"
                  class="mt-3"
                  ref="squadImagesRef"
                />
              </div>
            </div>
            <div class="col-md-6 product-image-preview">
              <div class="image-block">
                <template v-if="squadImageUrl !== null">
                  <img :src="squadImageUrl" alt="Level image" />
                </template>
                <template v-else>
                  <h6>No image added</h6>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div class="row py-1">
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="row py-1 flex-column">
          <label for="raw-materials">Raw Materials</label>
          <vue-tags-input
            v-model="rawmaterial"
            :tags="rawMaterials"
            placeholder="Add Raw materials"
            @tags-changed="newTags => (rawMaterials = newTags.text)"
          />
        </div>
        <div class="row py-1 flex-column">
          <label for="keywords">Keywords</label>
          <vue-tags-input
            v-model="keyword"
            :tags="keywordsTag"
            placeholder="Add Keywords"
            @tags-changed="tagsChangedKey"
          />
        </div>
        <div class="row d-flex justify-content-center p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";
import SearchStoreBar from "@/view/layout/dashboard_components/SearchStoreBar";
import VueTagsInput from "@johmun/vue-tags-input";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    VueTagsInput,
    SearchStoreBar
  },
  data() {
    return {
      form: {
        productName: "",
        sku: "",
        price: "",
        unit: "g",
        description: "",
        categoryId: null,
        subCategoryId: null,
        keywords: [],
        storeId: null,
        discountPercentage: "",
        discountPrice: "",
        isDiscounted: false,
        productType: "",
        squadType: false,
        businessLine: null,
        discountOnFour: 0,
        discountOnThree: 0,
        discountOnTwo: 0,
        ageLimited: false,
        scheduleOnly: false
      },
      categoryOptions: [
        {
          value: null,
          text: "All Categories",
          cats: []
        }
      ],
      unitOptions: [
        { text: "Unit", value: "unit" },
        { text: "Dozen", value: "doz" },
        { text: "Piece", value: "pc" },
        { text: "Box", value: "box" },
        { text: "MilliGram", value: "mg" },
        { text: "Gram", value: "g" },
        { text: "Kilogram", value: "kg" },
        { text: "Ounce", value: "oz" },
        { text: "Pound", value: "lbs" },
        { text: "Millilitre", value: "ml" },
        { text: "Litre", value: "ltr" },
        { text: "Gallon", value: "gal" }
      ],
      stores: [],
      callingFetchStores: false,
      callingFetchSubCats: false,
      images: null,
      squadDiscount: "",
      keywordsTag: [],
      rawMaterials: [],
      squadNumber: 2,
      keyword: "",
      rawmaterial: "",
      typeOptions: [
        { text: "Food", value: "Food" },
        { text: "Grocery", value: "Grocery" }
      ],
      squadOptions: [
        {
          text: "2",
          value: 2
        },
        {
          text: "3",
          value: 3
        },
        {
          text: "4",
          value: 4
        }
      ],
      imageUrl: null,
      squadImageUrl: null,
      squadImage: null,
      subCategoryOptions: null,
      processingRequest: "",
      hasError: false,
      processingError: "",
      catsDisabled: true,
      storesDisabled: true,
      subCategoryDisabled: true,
      storeOptions: []
    };
  },
  mounted: function() {
    this.$store.dispatch(GET_ALL_BUSINESS_LINES);
  },
  methods: {
    ...mapGetters(["getAllCategories", "getAllBusinessLines"]),
    extractTable: function(arr) {
      let array = [];
      array.push({
        value: null,
        text: "All Stores",
        cats: []
      });
      arr.map(item => {
        let s = {};
        s.value = item._id;
        if (item.name) {
          s.text = item.name;
        } else {
          s.text = item.storeName;
        }
        s.cats = item.categories;
        array.push(s);
      });
      this.stores = array;
    },
    showModal: function() {
      this.$bvModal.show("new-product-modal");
    },
    businessLineChanged: async function(val) {
      this.subCategoryDisabled = true;
      this.storesDisabled = true;
      this.catsDisabled = true;
      this.form.subCategoryId = null;
      this.subCategoryOptions = null;
      this.categoryOptions = null;
      if (val === null) return;
      let newItem = this.businessLines.find(
        item => item.value === this.form.businessLine
      );
      let name = newItem.text.toLowerCase();
      this.form.productType = newItem.text;
      if (this.form.businessLine !== null && name === "grocery") {
        await ApiService.post("/businessLine/getBusinessLinesCategories", {
          businessLineId: val
        }).then(({ data: resCategories }) => {
          const categoriesFetched = resCategories.data;
          let arr = [];
          if (categoriesFetched.length > 0) {
            this.categoryOptions = categoriesFetched.map(item => ({
              text: item.name,
              value: item._id
            }));
            this.categoryOptions.push({
              value: null,
              text: "All Categories"
            });
          } else {
            arr.push({
              value: null,
              text: "No Categories Found"
            });
            this.categoryOptions = arr;
          }
          /* Set the categories disabled to false */
          this.catsDisabled = false;
        });
      } else if (this.form.businessLine !== null) {
        /* If no businessline was selected disable the categories */
        this.storesDisabled = false;
        this.catsDisabled = true;
        this.callingFetchStores = true;
        const tk = JwtService.getToken();
        await ApiService.post("/store/showStoresbyBusinessLines", {
          token: `${tk}`,
          storeType: `${val}`
        }).then(res => {
          this.extractTable(res.data.data);
          this.callingFetchStores = false;
        });
        this.form.categoryId = null;
        this.form.storeId = null;
      }
    },
    catChanged: async function(value) {
      this.subCategoryOptions = null;
      this.form.subCategoryId = null;
      if (value === null) return;
      this.callingFetchSubCats = true;
      const { data: response } = await ApiService.get(
        `/subCategories/category/${value}`
      ).catch(err => console.log(err));
      if (!response.error) {
        if (response.subCategories.length < 1) {
          this.subCategoryOptions = [
            { text: "No Sub Category was found.", value: null }
          ];
          this.subCategoryDisabled = false;
        } else {
          this.subCategoryOptions = response.subCategories.map(item => ({
            text: item.name,
            value: item._id
          }));
          this.subCategoryOptions.push({ text: "SubCategories", value: null });
          this.subCategoryDisabled = false;
        }
      }
    },
    storeChanged: async function(val) {
      if (val === null) {
        return;
      }
      const { data: response } = await ApiService.get(
        `/subCategories/store/${this.form.storeId}`
      ).catch(err => console.log(err));
      if (!response.error) {
        if (response.subCategories.length < 1) {
          this.subCategoryOptions = [
            { text: "No Sub Category was found.", value: null }
          ];
          this.subCategoryDisabled = false;
        } else {
          this.subCategoryOptions = response.subCategories.map(item => ({
            text: item.name,
            value: item._id
          }));
          this.subCategoryOptions.push({ text: "SubCategories", value: null });
          this.subCategoryDisabled = false;
        }
      }
    },
    getStoreDetails: async function(obj) {
      this.subCategoryDisabled = true;
      this.form.subCategoryId = null;
      if (obj !== null || obj.length > 0) {
        this.stores = obj.map(item => ({
          text: item.storeName,
          value: item.storeId
        }));
        this.stores = [{ value: null, text: "All Stores" }, ...this.stores];
      }
    },
    discountPercentChange: function(val) {
      const { price } = this.form;
      if (price !== "" && price > 0) {
        let out = (price * val) / 100;
        this.form.discountPrice = out.toFixed(2);
      }
    },
    discountPriceChange: function(val) {
      const { price } = this.form;
      if (val > price) {
        this.form.discountPrice = price.toFixed(2);
        val = this.form.discountPrice;
      }
      if (price !== "" && price > 0) {
        const out = (val / price) * 100;
        this.form.discountPercentage = out.toFixed(2);
      }
    },
    uploadFile: function() {
      this.images = this.$refs.imagesRef.files[0];
      this.imageUrl = URL.createObjectURL(this.images);
    },
    uploadSquadImage: function() {
      this.squadImage = this.$refs.squadImagesRef.files[0];
      this.squadImageUrl = URL.createObjectURL(this.squadImage);
    },
    resetForm: function() {
      this.form.productName = "";
      this.form.price = "";
      this.form.unit = "";
      this.form.description = "";
      this.form.categoryId = null;
      this.form.subCategoryId = null;
      this.form.keywords = [];
      this.form.storeId = null;
      this.form.discountPercentage = "";
      this.form.discountPrice = "";
      this.form.isDiscounted = false;
      this.form.productType = "";
      this.images = null;
    },
    createNewProduct: async function(e) {
      e.preventDefault();
      const tk = JwtService.getToken();
      this.form.token = tk;
      ApiService.setHeader("content-type", "application/JSON");
      this.processingRequest = "processing";
      ApiService.post("/products/addProduct", this.form).then(async res => {
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 5000);
          return;
        }
        if (res.data.success) {
          if (this.images !== null) {
            ApiService.setHeader("content-type", "multipart/form-data");
            const formData = new FormData();
            formData.append("image", this.images);
            formData.append("productId", res.data.data._id);
            formData.append("replace", true);
            formData.append("token", `${tk}`);
            ApiService.post("/products/uploadImage", formData)
              .then(async () => {
                if (this.squadImage !== null) {
                  const newFormData = new FormData();
                  newFormData.append("image", this.squadImage);
                  newFormData.append("productId", res.data.data._id);
                  newFormData.append("replace", true);
                  newFormData.append("token", `${tk}`);
                  await ApiService.post(
                    "/products/uploadSquadImage",
                    newFormData
                  )
                    .then((/* cRes */) => {
                      this.processingRequest = "";
                      this.$bvModal.hide("new-product-modal");
                      setTimeout(() => {
                        this.$emit("updateProducts");
                      }, 100);
                      ApiService.setHeader("content-type", "application/json");
                    })
                    .catch((/* error */) => {
                      setTimeout(() => {
                        this.$emit("updateProducts");
                      }, 100);
                      ApiService.setHeader("content-type", "application/json");
                    });
                } else {
                  this.processingRequest = "";
                  ApiService.setHeader("content-type", "application/json");
                  this.$bvModal.hide("new-product-modal");
                  setTimeout(() => {
                    this.$emit("updateProducts");
                  }, 100);
                }
              })
              .catch(() => {
                setTimeout(() => {
                  this.$emit("updateProducts");
                }, 100);
                ApiService.setHeader("content-type", "application/json");
              });
          } else {
            this.processingRequest = "";
            this.$bvModal.hide("new-product-modal");
            setTimeout(() => {
              this.$emit("updateProducts");
            }, 100);
          }
        }
      });
    },
    tagsChangedKey: function(newTags) {
      let arr = [];
      newTags.map(item => {
        arr.push(item.text);
      });
      return (this.keywordsTag = newTags);
    }
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region,
      businessLines: state => {
        const arr = [];
        arr.push({ text: "Business Lines", value: null, cats: [], stores: [] });
        state.businessLines.allBusinessLines.map(item => {
          arr.push({
            text: item.name,
            value: item._id,
            cats: item.categories,
            stores: item.vendors
          });
        });
        return arr;
      }
    })
  }
};
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .new-product-modal {
  background: #ffffff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .image-upload {
    label {
      display: flex;
      align-items: center;
      span.icon {
        &.attachment {
          display: flex;
          .text {
            max-width: 100px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
  .product-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .group-prepend {
    display: flex;
    align-items: center;
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
    background: #fff;
    padding-right: 5px;
    i {
      margin: 0 5px;
    }
  }

  .vue-tags-input {
    max-width: 100%;
    width: 100%;
    .ti-tag {
      background: #fff;
      color: #000;
    }
  }
}
</style>
