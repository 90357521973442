<template>
  <div class="archived-products">
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center lead text-danger">
        <transition name="fade-in-up">
          <span v-if="archivingError !== ''">{{ archivingError }} </span>
        </transition></b-col
      >
    </b-row>

    <div class="row">
      <div class="col-lg-6 py-3">
        <h3>Archived Products</h3>
      </div>
      <div class="col-lg-3">
        <div style="display: grid; place-items: center" class="w-100 h-100">
          <span><b>Total Records:</b> {{ rows }}</span>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center">Products Per Page</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-4 offset-md-4">
        <form @submit.prevent="searchProducts">
          <div class="row d-flex align-items-center">
            <div class="col-9">
              <b-input
                type="text"
                v-model="searchText"
                placeholder="Search Vendor"
              />
            </div>
            <div class="col-3">
              <button class="go-btn" type="submit">Go</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-if="networkError === null">
      <b-table
        hover
        :busy="fetchingRecords"
        :items="tableProducts"
        :fields="tableFields"
        :per-page="perPage"
        class="table-light"
        thead-class="archived-products-table-head shadow-sm"
        tbody-class="archived-products-table-body"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mx-4"></b-spinner>
            <strong>Fetching...</strong>
          </div>
        </template>
        <template v-slot:cell(images)="data">
          <StackedImages :images="data.value" type="product" />
        </template>
        <template v-slot:cell(product_rating)="data">
          <div class="info-rating-block">
            <div class="info">
              <span class="ratings-block">
                <i
                  v-for="index in 5"
                  class="fa fa-star"
                  v-bind:class="getGoldClass(parseInt(data.value), index)"
                  :key="index"
                ></i>
              </span>
              <span style="margin-left: 2.5px">{{ data.value }}</span>
            </div>
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <div class="action-cell">
            <span
              class="action-btn unarchive"
              @click="unarchiveProduct(data.value)"
              >unarchive</span
            >
          </div>
        </template>
      </b-table>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-items-center w-100">
        <div class="text-danger text-center display-4 p-4">API Error</div>
        <div class="text-danger text-center lead pb-4">
          Error while fetchting archived products. Please refresh to try again.
        </div>
        <div class="text-danger text-center lead pb-4">
          {{ networkError }}
        </div>
      </div>
    </template>
    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        :current-page="currentPage"
        @change="changePage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { GET_ALL_ARCHIVED_PRODUCTS } from "@/core/services/store/products.module";
import { mapState, mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import StackedImages from "@/view/layout/dashboard_components/StackedImages";

export default {
  components: {
    StackedImages
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      tableFields: [
        {
          key: "code",
          label: "Code",
          sortable: true
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "price",
          label: "Price",
          sortable: true
        },
        {
          key: "discount_price",
          label: "Discount Price",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "product_rating",
          label: "Product Rating",
          sortable: true
        },
        {
          key: "product_rave",
          label: "Product Rave",
          sortable: true
        },
        {
          key: "images",
          label: "images",
          sortable: true
        },
        {
          key: "action",
          label: "Action",
          sortable: true
        }
      ],
      archivingError: ""
    };
  },
  methods: {
    ...mapGetters([
      "getAllProducts",
      "getProductsCount",
      "getFetchingProducts"
    ]),
    getGoldClass: function(total, key) {
      return key <= total ? "gold" : "";
    },
    updateProducts: function(options = {}) {
      const val = this.currentPage;
      options.limit = [(val - 1) * this.perPage, this.perPage];
      this.$store.dispatch(GET_ALL_ARCHIVED_PRODUCTS, options);
    },
    perPageChange(val) {
      const options = {};
      options.limit = [(this.currentPage - 1) * val, val];
      options.searchText = this.searchText;
      this.$store.dispatch(GET_ALL_ARCHIVED_PRODUCTS, options);
    },

    changePage(val) {
      const options = {};
      options.searchText = this.searchText;
      options.limit = [(val - 1) * this.perPage, this.perPage];
      this.$store.dispatch(GET_ALL_ARCHIVED_PRODUCTS, options);
    },
    unarchiveProduct(index) {
      // Archive the product cliked
      const item = this.tableProducts[index];
      const productId = item.id;
      const itemName = item.name;
      const token = JwtService.getToken();
      const dataToSend = {
        productId,
        token
      };
      // ApiService.setHeader("content-type", "application/x-www-form-urlencoded");
      ApiService.post("/products/unArchiveProduct", dataToSend)
        .then(res => {
          res = res.data;
          if (res.success) {
            this.archivingError = `${itemName} was un-archived.`;
            setTimeout(() => {
              this.archivingError = "";
            }, 5500);
            setTimeout(() => {
              this.updateProducts();
            }, 100);
          }
        })
        .catch(({ data }) => {
          this.archivingError = `Unarchive Error: ${data.message} on ${data.url}`;
          setTimeout(() => {
            this.archivingError = "";
          }, 5500);
        });
    },
    searchProducts: function() {
      this.updateProducts();
    }
  },
  mounted() {
    this.$store.dispatch(GET_ALL_ARCHIVED_PRODUCTS);
  },
  computed: {
    ...mapState({
      fetchingRecords: function(state) {
        return state.products.fetchingArchivedProducts;
      },
      rows: function(state) {
        return state.products.archivedProductsCount;
      },
      allProducts: function(state) {
        return state.products.archivedProducts;
      },
      networkError: function(state) {
        return state.products.archivedProductsError;
      },
      tableProducts: function(state) {
        let arr = [];
        state.products.archivedProducts.map(function(item, index) {
          const tableItem = {
            code: item.sku,
            name:
              item.name.length > 35
                ? item.name.substr(0, 34) + "..."
                : item.name,

            price: `PKR${item.price}`,
            discount_price: `PKR${item.discountedPrice}`,
            description:
              item.description.length > 75
                ? item.description.substr(0, 74) + "..."
                : item.description,
            product_rating: item.avgRating,
            product_rave: item.raving ? "Yes" : "No",
            images: item.images,
            action: index,
            id: item._id,
            store: item.store
          };
          arr.push(tableItem);
        });
        return arr;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.go-btn {
  color: #fff;
  background: #d8102a;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
}
::v-deep .b-table {
  .archived-products-table-head {
    tr {
      th {
        color: #763fb8;
        width: 8.75%;
        font-size: 11px;
        vertical-align: top;
        width: 7.14%;
        &:nth-child(6) {
          width: 20%;
        }
        &:nth-child(7) {
          width: 15%;
        }
        &:nth-child(10) {
          width: 15%;
        }
      }
    }
  }
  .archived-products-table-body {
    tr {
      td {
        vertical-align: middle;
        font-size: 12px;
        .action-cell {
          width: 100%;
          display: flex;
          .action-btn {
            width: 75px;
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            margin-right: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 15px;
            &.unarchive {
              background: #3fa9fb;
              color: #fff;
            }
          }
        }
        .info-rating-block {
          display: flex;
          flex-direction: column;
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            // font-weight: 600;
            .ratings-block {
              i {
                font-size: 10px;
              }
              i.gold {
                color: #ff9500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
